body {
  font-size: 32px;
}

small {
  font-size: 9px;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}
